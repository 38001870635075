.landscape-container {
    text-align: center;
    position: absolute;
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: black;
    width: 100vw;
    height: 100vh;
}

@media screen and (max-width: 600px) {
}

@media screen and (min-width: 600px) {
}

@media screen and (min-width: 960px) {
}

@media screen and (min-width: 1280px) {
}

@media screen and (min-width: 1920px) {
}

