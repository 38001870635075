@import "utils/variables";

.app-bg {
  overflow-x: hidden;
  background-color: black;
  width: 100vw;
  height: 100vh;
}

.app-container {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.display-actions-container {
  margin-top: auto;
  margin-bottom: 3%;
}

@media screen and (max-width: 600px) {
}

@media screen and (min-width: 600px) {
}

@media screen and (min-width: 960px) {
}

@media screen and (min-width: 1280px) {
}

@media screen and (min-width: 1920px) {
}

@media screen and (min-width: 2400px) {
}
