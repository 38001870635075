.loader-container {
  text-align: center;
  position: absolute;
  z-index: 9;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.9);
  width: 100vw;
  height: 100vh;

  div {
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
