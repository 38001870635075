.loop-slider {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: auto;
  height: 100%;
}

@media screen and (max-width: 600px) {

}

@media screen and (min-width: 600px) {

}

@media screen and (min-width: 960px) {

}

@media screen and (min-width: 1280px) {

}

@media screen and (min-width: 1920px) {

}

@media screen and (min-width: 2400px) {

  }
