@font-face {
  font-family: "Comfortaa";
  src: local("Comfortaa"),
  url("./assets/fonts/Comfortaa/Comfortaa-Regular.ttf")
  format("truetype");
  font-weight: normal;
}

@font-face {
  font-family: "Source Sans Pro";
  src: local("Source Sans Pro"),
    url("./assets/fonts/SourceSansPro/SourceSansPro-Regular.ttf")
      format("truetype");
  font-weight: normal;
}

@font-face {
  font-family: "Source Sans Pro Bold";
  src: local("Source Sans Prop Bold"),
    url("././assets/fonts/SourceSansPro/SourceSansPro-Bold.ttf")
      format("truetype");
  font-weight: bold;
}

@font-face {
  font-family: "Source Sans Pro Light";
  src: local("Source Sans Pro Light"),
    url("././assets/fonts/SourceSansPro/SourceSansPro-Light.ttf")
      format("truetype");
  font-weight: 100;
}

body {
  margin: 0;
  font-family: "Source Sans Pro", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 300;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

h1 {
  margin: 0;
}
