.player-actions-container {
  margin-top: 15px;
  width: 90%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-right: auto;
  margin-left: auto;
}

.player-actions {
  display: flex;
  flex-grow: 1;
  justify-content: center;
  flex-direction: row;

  & > :nth-child(2) {
    margin-left: 25px;
    margin-right: 25px;
  }
}

.play-pause-btn {
  border-radius: 99px;
  background-color: var(--color-dark-gray);
  padding: 10px 17px;
  cursor: pointer;

  &:hover svg path {
    fill: var(--color-secondary) !important;
  }
}

.share-btn {
  flex-grow: 2;
}

@media screen and (max-width: 600px) {
  .player-actions-container {
    margin-bottom: 28%;
  }
}

@media screen and (min-width: 600px) {
}

@media screen and (min-width: 960px) {
  .player-actions-container {
    width: 37%;
  }
}

@media screen and (min-width: 1280px) {
  .player-actions-container {
    width: 33%;
  }
}

@media screen and (min-width: 1920px) {
}
