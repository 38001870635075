
.share-modal-container {
  position: absolute;
  z-index: 1;
  overflow-x: hidden;
  width: 100vw;
  height: 100vh;
  background-color: black;
}

.share-header {
  display: flex;
  justify-content: flex-end;
  margin: 1.5% 1.5% 0 1.5%;
}

.modal-container__shareIcon {
  width: 10%;
  margin-top: 5%;
}

.shareIcon--align-center {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.share-title {
  color: var(--color-primary);
  font-size: 2rem;
  font-weight: 300;
  margin-top: 4%;
}

.share-title--align-center {
  text-align: center;
}

.share-buttons-container {
  margin-top: 3%;
  display: grid;
  width: 100%;
  height: 55%;
  grid-template-columns: repeat(1, 40%);
  justify-content: center;
  gap: 2%;
}

.close-modal-text-btn {
  color: var(--color-dark-gray);
  cursor: pointer;
  margin-top: 6%;
  font-size: 1.6rem;
  display: block;
}

.close-modal-text-btn--align-center {
  text-align: center;
}

.share-button {
  border: none;
  outline: none;
  color: white;
  border-radius: 99px;
  background-color: var(--color-primary);
  font-family: "Source Sans Pro Bold", serif;
  font-weight: bold;
  font-size: 1.7rem;
  padding: 15px 0 15px 0;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media screen and (min-width: 960px) {
  .share-buttons-container {
    grid-template-columns: repeat(2, 20%);
    row-gap: 15%;
    height: 30%;
  }

  .modal-container__shareIcon {
    width: 4.5%;
    margin-bottom: 2%;
  }

  .share-title {
    margin-top: 2%;
  }
}

@media screen and (min-width: 1280px) {
  .modal-container__shareIcon {
    width: 3.5%;
    margin-bottom: 2.5%;
  }
}

@media screen and (min-width: 1920px) {
  .modal-container__shareIcon {
    width: 3%;
  }
}
