.sliders {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  height: 45%;
  margin-top: 5%;

  & >:nth-child(2) {
    margin-left: 7%;
  }
  & >:nth-child(3) {
    margin-left: 7%;
    margin-right: 7%;
  }
}

@media screen and (max-width: 600px) {
  .sliders {
    height: 35%;
    margin-top: 20%;
    justify-content: space-evenly;
    & >:nth-child(2) {
      margin-left: 0;
    }
    & >:nth-child(3) {
      margin-left: 0;
      margin-right: 0;
    }
  }
}

@media screen and (min-width: 600px) {
  .sliders {
    height: 40%;
    margin-top: 17%;
    justify-content: space-evenly;
    & >:nth-child(2) {
      margin-left: 0;
    }
    & >:nth-child(3) {
      margin-left: 0;
      margin-right: 0;
    }
  }
}

@media screen and (min-width: 960px) {
  .sliders {
    justify-content: center;
    height: 45%;
    margin-top: 5%;

    & >:nth-child(2) {
      margin-left: 10%;
    }
    & >:nth-child(3) {
      margin-left: 10%;
      margin-right: 10%;
    }
  }
}

@media screen and (min-width: 1280px) {
  .sliders {
    margin-top: 6%;
  }
}

@media screen and (min-width: 1920px) {

}

@media screen and (min-width: 2400px) {

}
