.container {
  position: absolute;
  z-index: 1;
  overflow-x: hidden;
  width: 100vw;
  height: 100vh;
  background-color: black;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  margin-bottom: auto;
  margin-top: auto;
}

.presentation-content {
  text-align: center;
  margin-top: auto;
  margin-bottom: auto;
}

.header {
  display: flex;
  justify-content: flex-end;
  flex-direction: row;
  margin: 1% 1% 0 1%;
}

.presentation-text {
  font-size: 1.6rem;
  align-self: center;
  font-family: "Source Sans Pro Light", serif;
}

.presentation-text__primary {
  color: var(--color-primary);
}

.logo-presentation {
  width: 35%;
  align-self: center;
}

.mix-text {
  font-family: "Source Sans Pro Light", serif;
  font-size: 1.6rem;
  align-self: center;
}

.mix-text__primary {
  color: var(--color-primary);
}

.sound-text {
  font-family: "Source Sans Pro Light", serif;
  font-size: 1.6rem;
  align-self: center;
  margin: 5px 0 0 0;
}

.sound-text__primary {
  color: var(--color-primary);
}

.copyright-text {
  margin-top: auto;
  font-size: 1.6rem;
  align-self: center;
  font-family: "Source Sans Pro Light", serif;
  margin-bottom: 15%;
  text-align: center;
}

.copyright-text__primary {
  color: var(--color-primary);
}

.ready-btn {
  border: none;
  outline: none;
  color: white;
  border-radius: 99px;
  background-color: var(--color-primary);
  font-size: 2rem;
  align-self: center;
  font-family: "Source Sans Pro Bold", serif;
  font-weight: bold;
  cursor: pointer;
  width: 10%;
  padding: 10px 7px;
}

@media screen and (max-width: 600px) {
  .header {
    margin: 2.5% 2.5% 0 2.5%;
  }

  .presentation-text {
    font-size: 1.3rem;
  }

  .logo-presentation {
    width: 80%;
  }

  .mix-text {
    font-size: 1.3rem;
    width: 180px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 35px;
  }

  .ready-btn {
    width: 37%;
    padding: 7px;
    font-size: 2rem;
  }

  .sound-text {
    font-size: 1.3rem;
    margin-top: 25px;
  }

  .copyright-text {
    font-size: 1.1rem;
    margin-bottom: 30%;
  }
}

@media screen and (min-width: 600px) {
  .header {
    margin: 2.5% 2.5% 0 2.5%;
  }

  .presentation-text {
    font-size: 1.6rem;
  }

  .logo-presentation {
    width: 80%;
  }

  .mix-text {
    font-size: 1.6rem;
  }

  .ready-btn {
    width: 30%;
    padding: 10px 7px;
    font-size: 2.8rem;
  }

  .sound-text {
    margin-top: 25px;
    font-size: 1.6rem;
  }

  .copyright-text {
    font-size: 1.3rem;
  }
}

@media screen and (min-width: 960px) {
  .logo-presentation {
    width: 50%;
  }
  .ready-btn {
    width: 20%;
    font-size: 1.8rem;
    padding: 15px 5px;
  }
}

@media screen and (min-width: 1280px) {
  .header {
    margin: 2% 2% 0 2%;
  }

  .presentation-text {
    font-size: 1.6rem;
  }

  .logo-presentation {
    width: 40%;
  }

  .mix-text {
    font-size: 1.6rem;
  }

  .ready-btn {
    width: 13%;
    font-size: 1.8rem;
  }

  .sound-text {
    font-size: 1.6rem;
  }

  .copyright-text {
    font-size: 1.3rem;
    margin-bottom: 5%;
  }
}

@media screen and (min-width: 1920px) {
  .header {
    margin: 1.5% 1.5% 0 1.5%;
  }

  .presentation-text {
    font-size: 1.6rem;
  }

  .logo-presentation {
    width: 25%;
  }

  .mix-text {
    font-size: 1.6rem;
  }

  .ready-btn {
    width: 10%;
    font-size: 1.8rem;
  }

  .sound-text {
    font-size: 1.6rem;
  }

  .copyright-text {
    font-size: 1.3rem;
    margin-bottom: 2%;
  }
}
