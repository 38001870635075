.contact-container {
  position: absolute;
  z-index: 1;
  overflow-x: hidden;
  width: 100vw;
  height: 100vh;
  background-color: black;
}

.contact-header {
  display: flex;
  justify-content: flex-end;
  margin: 2.5% 2.5% 0 2.5%;
}

.contact-content {
  text-align: center;
  margin-top: 7%;
}

.contact-input-container {
  margin-top: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.contact-content__giftIcon {
  width: 10%;
  margin-bottom: 3.8%;
  display: block;
  margin-left:auto;
  margin-right: auto;
  fill: var(--color-primary);
}

.contact-content__logoMom {
  width: 76%;
}

.contact-input {
  outline: none;
  font-size: 1.4rem;
  font-family: "Source Sans Pro Light", serif;
  font-weight: 100;
  border-radius: 99px;
  width: 70%;
  height: 7%;
  padding: 15px 20px 15px 30px;
  box-sizing: border-box;
  border: 1px solid transparent;

  &::placeholder {
    font-size: 1.4rem;
  }
}

.contact-input--error {
  border: 1px solid red;
  color: red;
}

.send-btn {
  border: 1px solid transparent;
  width: 70%;
  outline: none;
  color: white;
  border-radius: 99px;
  padding: 15px 20px;
  margin-top: 10%;
  background-color: var(--color-primary);
  font-size: 1.4rem;
  font-family: "Source Sans Pro Bold", serif;
  cursor: pointer;
}

.info-text {
  font-family: "Source Sans Pro Light", serif;
  font-weight: 100;
  color: var(--color-primary);
  font-size: 1.2rem;
  margin-top: 7%;
}

.close-btn {
  color: var(--color-dark-gray);
  cursor: pointer;
  font-size: 1.6rem;
  margin-top: 8%;
  display: inline-block;
}


@media screen and (max-width: 600px) {
  .contact-content__logoMom {
    margin-top: 20px;
  }

  .send-btn {
    font-size: 1.2rem;
  }

  .contact-input {
    font-size: 1.2rem;
  }

  .info-text {
    width: 180px;
    font-size: 1.4rem;
    margin-right: auto;
    margin-left: auto;
  }
}

@media screen and (min-width: 600px) {
  .contact-header {
    display: flex;
    justify-content: flex-end;
    margin: 2.5% 2.5% 0 2.5%;
  }

  .contact-content__giftIcon {
    width: 7%;
    margin-bottom: 3%;
  }

  .contact-content__logoMom {
    width: 70%;
  }

  .info-text {
    margin-top: 2.5%;
    font-size: 1.6rem;
  }

  .contact-input {
    width: 50%;
  }

  .send-btn {
    width: 50%;
    margin-top: 6%;
  }

  .close-btn {
    margin-top: 5%;
  }
}

@media screen and (min-width: 960px) {
  .contact-header {
    display: flex;
    justify-content: flex-end;
    margin: 1.5% 1.5% 0 1.5%;
  }

  .contact-content {
    text-align: center;
    margin-top: 7%;
  }

  .contact-input-container {
    flex-direction: row;
  }

  .contact-content__giftIcon {
    width: 4.5%;
    margin-bottom: 2%;
  }

  .contact-content__logoMom {
    width: 35%;
  }

  .contact-input {
    width: 20%;
  }

  .send-btn {
    width: 23%;
    margin-top: 0;
    margin-left: 15px;
  }

  .info-text {
    margin-top: 0.8%;
    font-size: 1.6rem;
  }

  .close-btn {
    margin-top: 3.5%;
  }
}

@media screen and (min-width: 1280px) {
  .contact-input {
    width: 17%;
  }

  .send-btn {
    width: 17%;
    margin-top: 0;
    margin-left: 15px;
  }
}

@media screen and (min-width: 1920px) {
}
