.music-display {
  display: block;
  color: white;
  font-size: 2.6rem;
  font-family: "Source Sans Pro";
  font-weight: normal;
  text-align: center;
  margin-bottom: 2%;
}

@media screen and (max-width: 600px) {
  .music-display {
    font-size: 1.6em;
    margin-bottom: 10%;
  }
}

@media screen and (min-width: 600px) {
  .music-display {
    font-size: 1.6em;
    margin-bottom: 7%;
  }
}

@media screen and (min-width: 960px) {
  .music-display {
    font-size: 2em;
    margin-bottom: 2.5%;
  }
}

@media screen and (min-width: 1280px) {
  .music-display {
    font-size: 2.5em;
    margin-bottom: 2.5%;
  }
}

@media screen and (min-width: 1920px) {
  .music-display {
    font-size: 3em;
    margin-bottom: 2.5%;
  }
}
