.app-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 2.5% 2.5% 0 2.5%;
}

.header-logo {
  width: 40%;
}

.header-btn {
  display: flex;
  align-items: center;
  border-radius: 99px;
  background-color: var(--color-dark-gray);
  padding: 16px 16px;
  cursor: pointer;

  &:hover svg path {
    fill: var(--color-secondary) !important;
  }
}

.header-giftIcon {
  width: 25px;
  fill: var(--color-primary);
}

.contact-section {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: auto;
}

.logoDjv {
  margin-right: 10px;
  font-family: "Comfortaa", "Source Sans Pro", serif;
  font-size: 1.2rem;
  color: var(--color-primary);
}

@media screen and (max-width: 600px) {
  .header-btn {
    padding: 10px;
  }
  .header-giftIcon {
    width: 15px;
  }
  .app-header {
    margin: 2% 2% 0 2%;
  }
  .header-logo {
    width: 42%;
  }
  .logoDjv {
    font-size: 1.1rem;
  }
}

@media screen and (min-width: 600px) {

}

@media screen and (min-width: 960px) {
  .app-header {
    margin: 1.5% 1.5% 0 1.5%;
  }
}

@media screen and (min-width: 1280px) {
  .header-btn {
    padding: 12px;
  }
  .header-logo {
    width: 22%;
  }
  .header-giftIcon {
    width: 22px;
  }
}

@media screen and (min-width: 1920px) {
  .header-logo {
    width: 21%;
  }
}

@media screen and (min-width: 2400px) {
  .header-giftIcon {
    width: 31px;
  }
}
