.confirm-modal {
  display: none;
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.98);
  z-index: 2;
  overflow: hidden;
}

.confirm-modal--visible {
  display: block !important;
}

.confirm-modal__content {
  height: auto;
  border-radius: 10px;
  background-color: transparent;
  display: flex;
  flex-direction: column;
  margin-top: 10%;
  padding: 20px;
  margin-left: auto;
  margin-right: auto;
  position: relative;
}

.content__message {
  color: white;
  font-size: 1.7rem;
}

.confirm-btn {
  border: 1px solid transparent;
  width: 150px;
  margin-left: auto;
  margin-right: auto;
  outline: none;
  color: white;
  border-radius: 99px;
  padding: 15px 20px;
  margin-top: 3%;
  background-color: var(--color-primary);
  font-size: 1.4rem;
  font-family: "Source Sans Pro Bold", serif;
  cursor: pointer;
}

@media screen and (max-width: 600px) {
  .content__message {
    font-size: 1.4rem;
  }
}

